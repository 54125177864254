const migratedUrls = [
    '/dashboard/consultadashboard',
    '/Usuarios/ConsultaEmpresas',
    '/Usuarios/ConsultaRolesCnpj',
    '/Usuarios/AtualizaSubsidiariaPadrao',
    '/Usuarios/ConsultaUsuario',
    '/Compras/AplicarManifestacao',
    '/Compras/AplicarListaManifestacao',
    '/empresas/consultaempresa',
    '/empresas/consultacompletarempresa',
    '/Compras/consultacomprasmanifestacao',
    '/Compras/DownloadXml',
    '/ComprasDetalhes/ConsultaComprasDetalhe',
    '/Compras/ConsultaCompras',
    '/Usuarios/AlterarSenha',
    '/Empresas/EditarEmpresa',
    '/Empresas/CompletarEmpresa',
    '/GerenciarUsuarios/SalvarNovoUsuario',
];

export function isUrlMigrated(url: string): boolean {
    for (const migratedUrl of migratedUrls) {
        if (url.toLowerCase().includes(migratedUrl.toLowerCase())) {
            return true;
        }
    }
    return false;
}
