import { inject } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HttpRequest, HttpEvent, HttpErrorResponse, HttpInterceptorFn, HttpHandlerFn } from '@angular/common/http';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { Loading, Notify } from 'notiflix';
import { isUrlMigrated } from '../const/migrated-urls';

export const jwtInterceptor: HttpInterceptorFn = (request: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<any>> => {
    const authenticationService = inject(AuthenticationService);

    function injectToken(req: HttpRequest<any>) {
        const user = authenticationService.user ?? null;
        return req.clone({
            setHeaders: {
                domeApiToken: `${user ? getToken(req.url) : ''}`,
                SecretTokenApiKey: 'a3ad-bd4e36a5beb6',
                CnpjFilial: `${user ? authenticationService.getActiveEmitterCnpj() : ''}`,
            },
        });
    }

    function getToken(url: string): string {
        if (!url.includes('api/v1') || isUrlMigrated(url)) {
            return authenticationService.getTokenDomeCloud() ?? '';
        } else {
            return authenticationService.getTokenDomeOld() ?? '';
        }
    }

    const cloned = injectToken(request);

    return next(cloned).pipe(
        catchError((error: HttpErrorResponse) => {
            if (error && [401, 403].includes(error.status) && (authenticationService.userValue || localStorage.getItem('accessToken'))) {
                Loading.remove();
                Notify.failure('Sessão expirada, faça login novamente');
                authenticationService.logout();
                return throwError(() => error);
            } else {
                return throwError(() => error);
            }
        })
    );
};
