<div class="sidebar">
    <div class="icons">
        <ng-container *ngFor="let menuItem of menuItens">
            <em
                *ngIf="menuItem.active"
                nz-icon
                [nzType]="menuItem.icon"
                nzTheme="outline"
                nz-tooltip
                [nzTooltipTitle]="menuItem.label"
                [routerLink]="menuItem.route"></em>
        </ng-container>
    </div>
</div>
<div class="navbar">
    <a class="logo">
        <img src="assets/logo/logo.png" alt="Linx" />
    </a>
    <div class="wrapper">
        <div class="left-wrapper">
            <p class="mrAuto title main-title">LINX DOME</p>
            <div class="user-wrapper">
                <div>{{ userData.nomeFantasia }} - {{ userData.cnpjCpf }}</div>
                <div>{{ userData.razaoSocialNomeCompleto }}</div>
                @if (certificado.statusCertificado && certificado.iconeCertificado) {
                    <p class="cardText">
                        <span style="margin-right: 0.5rem" nz-icon [nzType]="certificado.iconeCertificado" nzTheme="outline"></span>
                        <span [innerHtml]="certificado.mensagemCertificado"></span>
                    </p>
                }
            </div>
        </div>
        <div class="right-wrapper">
            <ng-container *ngIf="!licenseStatus || +licenseStatus !== 2">
                <div class="navLink" routerLink="meus-dados">
                    <em nz-icon nzType="database" nzTheme="outline"></em>
                    <p class="nav-link-text">Dados Empresa</p>
                </div>

                <div class="navLink" routerLink="gerenciar-usuarios">
                    <em nz-icon nzType="usergroup-add" nzTheme="outline"></em>
                    <p class="nav-link-text">Gerenciar Usuários</p>
                </div>
                <div class="navLink" routerLink="gerenciar-perfis">
                    <em nz-icon nzType="profile" nzTheme="outline"></em>
                    <p class="nav-link-text">Gerenciar Perfis</p>
                </div>
            </ng-container>
            <div class="navLink" routerLink="gerenciar-filiais">
                <em nz-icon nzType="setting" nzTheme="outline"></em>
                <p class="nav-link-text">Gerenciar Filiais</p>
            </div>
            <div class="navLink" (click)="logOut()">
                <em nz-icon nzType="logout" nzTheme="outline"></em>
                <p class="nav-link-text">Sair</p>
            </div>
        </div>
    </div>
</div>
<div class="content">
    <router-outlet></router-outlet>
</div>
<p class="version">2.0.0</p>
