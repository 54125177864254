import { Component, OnDestroy, OnInit } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ERoles } from '../../enums/roles.enum';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { IUserEconomicGroupEmitter } from '../../interfaces/authentication/user-economic-group-emitter.interface';
import { UserUtilsService } from '../../utils/user-utils/user-utils.service';
import { UserService } from '../../services/user/user.service';
import { ISubsidiary } from '../../interfaces/user/subsidiary.interface';

@Component({
    selector: 'app-dome',
    templateUrl: './dome.component.html',
    styleUrls: ['./dome.component.scss'],
    standalone: true,
    imports: [RouterModule, CommonModule, NzToolTipModule, NzIconModule],
})
export class DomeComponent implements OnInit, OnDestroy {
    public menuItens: IMenuItem[] = [
        { icon: 'dashboard', label: 'Dashboard', route: 'dashboard', active: false, permission: ERoles.DASHBOARD },
        { icon: 'shopping-cart', label: 'Compras', route: 'compras', active: false, permission: ERoles.NOTA_DE_ENTRADA },
        { icon: 'account-book', label: 'Vendas', route: 'vendas', active: false, permission: ERoles.NOTA_DE_SAIDA },
        { icon: 'question-circle', label: 'Perguntas Frequentes', route: 'faq', active: false, permission: '' },
    ];
    public userData!: IUserEconomicGroupEmitter;

    public licenseStatus = 0;

    public certificado = {
        statusCertificado: null,
        iconeCertificado: null,
        mensagemCertificado: null,
    };

    private destroy$ = new Subject();

    constructor(
        private authenticationService: AuthenticationService,
        private userUtilsService: UserUtilsService,
        private userService: UserService
    ) {
        this.authenticationService.user.pipe(takeUntil(this.destroy$)).subscribe((user) => {
            if (user) {
                this.licenseStatus = user.licenca;
                this.getUserData();
                if (this.licenseStatus === 2) {
                    this.menuItens = [];
                } else {
                    this.menuItens = this.menuItens.map((item) => {
                        if (item.permission) {
                            item.active = this.userUtilsService.findRole(item.permission);
                        } else {
                            item.active = true;
                        }
                        return item;
                    });
                }
            }
        });
    }

    ngOnInit(): void {
        this.getUserData();
    }

    logOut() {
        this.authenticationService.logout();
    }

    ngOnDestroy() {
        this.destroy$.next(false);
        this.destroy$.complete();
    }

    getUserData() {
        this.userData = this.authenticationService.userValue.economicGroup.emitters[0];
        this.certificado = this.userService.getCertificateData(this.userData as ISubsidiary);
    }
}

interface IMenuItem {
    active: boolean;
    icon: string;
    label: string;
    route: string;
    permission: string;
}
